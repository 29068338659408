export function getDPR() {
  return window.devicePixelRatio.toFixed(1) ?? '1';
}

export function setDPR(dpr, url = '') {
  const searchTerm = 'f_auto';
  if (url.includes(searchTerm)) {
    const urlSplit = url.split(searchTerm);
    return `${urlSplit[0]}${searchTerm}/dpr_${dpr}${urlSplit[1]}`;
  }
  return url;
}

// Set dimension specified of image to size (px)
export function setSize(url, measurement, dimension) {
  const startIndex = url.indexOf('w_');
  const endIndex = url.indexOf(',', startIndex);
  const originalWidth = url.slice(startIndex, endIndex);
  const urlSplit = url.split(originalWidth);
  return `${urlSplit[0]}${dimension}_${measurement}${urlSplit[1]}`;
}

export function getUrl(portraitURL, landscapeURL, width, height, isPortrait) {
  let dimension = 'w';
  let measurement = width;
  if (height >= width) {
    dimension = 'h';
    measurement = height;
  }
  return setSize(
    setDPR(getDPR(), isPortrait ? portraitURL : landscapeURL),
    measurement,
    dimension,
  );
}

export const getMediaFromRef = target => target?.firstChild ?? null;
// checks if the image has been already loaded by lcp-image-loader.js
export const shouldImageBeLoaded = target =>
  getMediaFromRef(target)?.dataset?.lcpLoaded !== 'true';

export const getResolvedImgUrl = ({
  imageRef,
  portraitURL,
  landscapeURL,
  width,
  height,
  dimensionType,
}) =>
  shouldImageBeLoaded(imageRef.current)
    ? getUrl(
        portraitURL,
        landscapeURL,
        width,
        height,
        dimensionType?.isPortrait,
      )
    : getMediaFromRef(imageRef.current)?.src;
